import * as React from 'react'

import { IconCheckCircle, Stack, Text } from '@adverity/design-system'

import type { PasswordRule } from '../utils'

type Props = {
    rules: ReadonlyArray<PasswordRule>
}

export const PasswordRules = ({ rules }: Props) => (
    <Stack spacing="x-small">
        <Text>Password must contain:</Text>

        <Stack spacing="xx-small" as="ul" style={{ listStyle: 'none' }}>
            {rules.map((rule) => (
                <Stack key={rule.text} as="li" spacing="xx-small" orientation="horizontal" alignItems="center">
                    <IconCheckCircle size="x-small" color={rule.valid ? 'brandHighlighted' : 'textSecondary'} />
                    <Text color={rule.valid ? 'brandHighlighted' : undefined}>{rule.text}</Text>
                </Stack>
            ))}
        </Stack>
    </Stack>
)
