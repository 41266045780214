import * as React from 'react'

import { Stack } from '@adverity/design-system'

type Props = {
    errors: ReadonlyArray<string>
}

export const Errors = ({ errors }: Props) => (
    <Stack spacing="xx-small">
        {errors.map((error) => (
            <div key={error} className="error">
                {error}
            </div>
        ))}
    </Stack>
)
